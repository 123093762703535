<template>
  <div class="reset-password-form-content">
    <div class="reset-password-form-header">
      Password reset
    </div>
    <div class="reset-password-form-header-description">
      {{ firstStep
        ? "Enter your username and we'll send you a verification code via your email"
        : 'We just sent a verification code to your email adress, please enter the code you received and new password down below'
      }}
    </div>
    <form
      class="reset-password-form"
      v-if="firstStep"
      @submit.prevent="sendCodeToEmail"
    >
      <div class="reset-password-form-label">
        Username
      </div>
      <div class="reset-password-form-input">
        <input
          type="text"
          placeholder="Insert your username"
          v-model="username"
          @input="resetErrorMessage"
          autocomplete="username"
        >
      </div>
      <div
        class="reset-password-error-message"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </div>
      <div class="reset-password-form-footer send-code">
        <div class="buttons-wrapper">
          <button
            type="submit"
            :class="['reset-btn brand', {'disabled': !!errorMessage || sendingEmail}]"
            :disabled="!!errorMessage || sendingEmail"
          >
            Send code
          </button>
        </div>
      </div>
    </form>
    <form
      class="reset-password-form"
      v-else
      @submit.prevent="changePassword"
    >
      <div class="reset-password-form-label">
        Your username
      </div>
      <input
        type="text"
        class="username-field"
        :value="username"
        autocomplete="username"
        disabled
      >
      <div class="reset-password-form-label">
        Verification code
      </div>
      <div class="reset-password-form-input first">
        <input
          type="text"
          placeholder="Enter verification code"
          v-model="formData.verificationCode"
          @input="resetErrorMessage"
        >
      </div>
      <div
        class="reset-password-error-message code"
        v-if="codeErrorMessage"
      >
        {{ codeErrorMessage }}
      </div>
      <div class="reset-password-form-label">
        New password
      </div>
      <div class="reset-password-form-input password first">
        <input
          :type="!passwordVisible ? 'password' : 'text'"
          placeholder="Your Password"
          v-model="formData.newPassword"
          @input="resetErrorMessage"
        >
        <div
          class="visibility-button"
          @click="togglePassVisibility"
        >
          <EyeIcon v-if="!passwordVisible" />
          <EyeOffIcon v-else />
        </div>
      </div>
      <PasswordPolicy
        :password="formData.newPassword"
      />
      <div class="reset-password-form-label">
        Repeat password
      </div>
      <div class="reset-password-form-input password">
        <input
          :type="!passwordVisible ? 'password' : 'text'"
          placeholder="Your Password"
          v-model="formData.repeatedPassword"
          @input="resetErrorMessage"
        >
        <div
          class="visibility-button"
          @click="togglePassVisibility"
        >
          <EyeIcon v-if="!passwordVisible" />
          <EyeOffIcon v-else />
        </div>
      </div>
      <div
        class="reset-password-error-message"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </div>
      <div class="reset-password-form-footer">
        <div class="buttons-wrapper">
          <div
            :class="['reset-btn resend', {'disabled': resendCodeInterval}]"
            @click="resendCode"
          >
            Resend code {{ resendCodeInterval ? `(${resendCodeInterval}s)` : '' }}
          </div>
          <button
            type="submit"
            :class="['reset-btn brand', {'disabled': !!errorMessage || changingPasswordProcessing}]"
            :disabled="!!errorMessage || changingPasswordProcessing"
          >
            Reset password
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import PasswordPolicy from './PasswordPolicy';

export default {
  components: {
    EyeIcon,
    EyeOffIcon,
    PasswordPolicy,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const passwordVisible = ref(false);
    const togglePassVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };
    const errorMessage = ref('');
    const codeErrorMessage = ref('');
    const resetErrorMessage = () => {
      if (errorMessage.value) errorMessage.value = '';
      if (codeErrorMessage.value) codeErrorMessage.value = '';
    };

    const firstStep = ref(true);

    const username = ref('');
    const formData = ref({
      newPassword: '',
      repeatedPassword: '',
      verificationCode: '',
    });

    const resendCodeInterval = ref(60);

    const sendingEmail = ref(false);
    const changingPasswordProcessing = ref(false);

    const sendCodeToEmail = () => {
      if (sendingEmail.value) return;
      resetErrorMessage();
      sendingEmail.value = true;
      store.dispatch('sendCodeToEmail', username.value)
        .then(() => {
          sendingEmail.value = false;
          firstStep.value = false;
          resendCodeInterval.value = 60;
          const resendInterval = setInterval(() => {
            resendCodeInterval.value -= 1;
            if (resendCodeInterval.value === 0) clearInterval(resendInterval);
          }, 1000);
        })
        .catch((err) => {
          sendingEmail.value = false;
          errorMessage.value = err.message;
        });
    };

    const resendCode = () => {
      if (resendCodeInterval.value) return;
      sendCodeToEmail();
    };

    const changePassword = () => {
      if (changingPasswordProcessing.value) return;
      resetErrorMessage();
      changingPasswordProcessing.value = true;
      if (formData.value.newPassword !== formData.value.repeatedPassword) {
        errorMessage.value = 'The password you entered does not match';
        changingPasswordProcessing.value = false;
        return;
      }
      store.dispatch('confirmNewPassword', {
        newPassword: formData.value.newPassword,
        username: username.value,
        verificationCode: formData.value.verificationCode,
      })
        .then(() => {
          store.dispatch('login', { username: username.value, password: formData.value.newPassword })
            .then(() => {
              changingPasswordProcessing.value = false;
              router.push({ name: 'home' });
            })
            .catch(() => {
              changingPasswordProcessing.value = false;
              router.push({ name: 'login' });
            });
        })
        .catch((err) => {
          changingPasswordProcessing.value = false;
          if (err.code === 'CodeMismatchException') {
            codeErrorMessage.value = err.message;
          } else {
            errorMessage.value = err.message;
          }
        });
    };

    const cancelReset = () => {
      router.push({ name: 'login' });
    };

    return {
      formData,
      changePassword,
      passwordVisible,
      togglePassVisibility,
      errorMessage,
      codeErrorMessage,
      resetErrorMessage,
      changingPasswordProcessing,
      firstStep,
      sendCodeToEmail,
      sendingEmail,
      username,
      resendCodeInterval,
      resendCode,
      cancelReset,
    };
  },
};
</script>

<style lang="scss">

.reset-password-form-content {
  display: flex;
  flex-direction: column;

  .reset-password-form-header {
    color: #191414;
    font-family: Poppins;
    font-size: 19.8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  .reset-password-form-header-description {
    color: #6D6D6D;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    margin-bottom: 40px;
  }

  .reset-password-form {
    display: flex;
    flex-direction: column;

    .reset-password-form-label {
      overflow: hidden;
      color: #6D6D6D;
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 2px;
    }

    .username-field {
      display: flex;
      height: 44px;
      min-width: 24px;
      padding: 0px 8px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
      background: #F0F0F0;
      border: none;
      margin-bottom: 24px;
      cursor: not-allowed;
      overflow: hidden;
      color: #A9A9A9;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: Rubik;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
    }

    .reset-password-form-input {
      height: 44px;
      display: flex;

      &.first {
        margin-bottom: 24px;
      }

      &.password {
        position: relative;
        margin-bottom: 0;

        &.first {
          margin-bottom: 24px;
        }
      }

      input {
        height: 100%;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        border: none;
        outline: none;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        background: #FFF;
        box-shadow: none;
        outline-offset: 1px;
        padding: 0 8px;

        &::placeholder {
          color: #CDCDCD;
        }

        &:hover {
          border-color: #CDCDCD;
        }

        &:focus-visible {
          border-color: #CDCDCD;
          outline: 2px solid #A2BFFF;
        }
      }

      .visibility-button {
        height: 43px;
        width: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;

        svg {
          height: 20px;
          width: 20px;
          color: #000;
        }
      }
    }

    .reset-password-error-message {
      font-size: 12px;
      line-height: 14px;
      margin-top: 8px;
      color: #FF2E2D;

      &.code {
        margin-top: -16px;
        margin-bottom: 24px;
      }
    }

    .reset-password-form-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;

      &.send-code {
        justify-content: flex-end;
      }

      .cancel-button {
        height: 100%;
        display: flex;
        align-items: center;
        color: #191414;
        padding: 0 12px;
        cursor: pointer;
      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        width: 100%;
        gap: 16px;

        .reset-btn {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          padding: 0 8px;
          width: 100%;
          height: 44px;
          min-height: 44px;
          max-height: 44px;
          box-shadow: none;

          &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }

          &.brand {
            background-color: #003C3C;
            color: #fff;
          }

          &.resend {
            min-width: 120px;
            background-color: #003C3C;
            color: #fff;

            &.disabled {
              color: #A9A9A9;
              background-color: #DDDDDD;
              border: 1px solid #F0F0F0;
            }
          }
        }
      }
    }
  }

  .password-policy {
    margin-top: -24px;
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 650px) {
  .reset-password-form-content {
    .reset-password-form {
      .reset-password-form-footer {
        .buttons-wrapper {
          flex-direction: row;
          justify-content: space-between;

          .reset-btn {
            width: auto;

            &.brand {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
</style>
