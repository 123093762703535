import { isString } from 'lodash';

export const makePasswordRule = (options) => {
  const { id, label, validator } = options ?? {};
  const safeValidator = (value) => isString(value) && validator(value);

  return {
    id,
    label,
    validator: safeValidator,
  };
};

export const makePasswordPolicy = (...rules) => {
  const { ids, rulesById } = rules.reduce(
    (normalized, rule) => ({
      ids: [
        ...normalized.ids,
        rule.id,
      ],
      rulesById: {
        ...normalized.rulesById,
        [rule.id]: rule,
      },
    }),
    {
      ids: [],
      rulesById: {},
    },
  );

  const validate = (value) => ids.reduce(
    (validationResult, ruleId) => ({
      ...validationResult,
      [ruleId]: rulesById[ruleId].validator(value),
    }),
    {},
  );

  return {
    ids,
    rulesById,
    validate,
  };
};

export const minimumLengthRule = makePasswordRule({
  id: 'length',
  label: '16 characters minimum',
  validator: (value) => value.length >= 16,
});

export const lowercaseRule = makePasswordRule({
  id: 'lowercase',
  label: '1 lowercase minimum',
  validator: (value) => /[a-z]/.test(value),
});

export const uppercaseRule = makePasswordRule({
  id: 'uppercase',
  label: '1 uppercase minimum',
  validator: (value) => /[A-Z]/.test(value),
});

export const numberRule = makePasswordRule({
  id: 'number',
  label: '1 number minimum',
  validator: (value) => /\d/.test(value),
});

export const specialCharacterRule = makePasswordRule({
  id: 'special',
  label: '1 special character minimum',
  validator: (value) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g.test(value),
});

export default makePasswordPolicy(
  minimumLengthRule,
  lowercaseRule,
  uppercaseRule,
  numberRule,
  specialCharacterRule,
);
