<template>
  <div class="password-policy">
    <ul class="password-policy__list">
      <li
        v-for="id in ids"
        :key="id"
        :class="[
          'password-policy__rule',
          { 'password-policy__rule--matched': result[id] },
        ]"
      >
        <div class="password-policy__rule-checkbox">
          <CheckIcon class="password-policy__rule-checkmark" />
        </div>
        <div class="password-policy__rule-label">
          {{ getLabelById(id) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { computed } from 'vue';
import { CheckIcon } from '@heroicons/vue/solid';
import policy from '@/services/helpers/password-policy';

const { ids, rulesById, validate } = policy;

export default {
  components: {
    CheckIcon,
  },
  props: {
    password: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const result = computed(() => validate(props.password));
    const getLabelById = (id) => rulesById[id]?.label;

    return {
      ids,
      result,
      getLabelById,
    };
  },
};
</script>

<style lang="scss">
.password-policy {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 8px 0;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__rule {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6D6D6D;

    &-checkbox {
      background-color: #CDCDCD;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      padding: 1.5px;
    }

    &-checkmark {
      fill: #fff;
      width: 100%;
      height: 100%;
    }
  }

  .password-policy__rule.password-policy__rule--matched {
    .password-policy__rule-checkbox {
      background-color: #003C3C;
    }
  }
}
</style>
